<template>
    <Header header__white="header__white" />
    <Breadcrumb title="Ярилцлага" subtitle="Ярилцлага" />
      <div class="testimonial__item-3">
         <div class="row">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-10 mt-25 mb-25" v-for="interview in interviews" :key="interview.index">
               <div class="testimonial__video ml-70 fix">
                  <div class="testimonial__thumb-3">
                  <iframe :src="interview.url" title="Санчир гарагийн зочин" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                  <div class="testimonial__video-content d-sm-flex">
                     <div class="testimonial__video-icon mr-20 mb-20">
                        <span>
                           <svg version="1.1" id="educal-youtube" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
                              <path class="st0" d="M22,11.1V12c0,5.5-4.5,10-10,10C6.5,22,2,17.5,2,12C2,6.5,6.5,2,12,2c1.4,0,2.8,0.3,4.1,0.9"/>
                              <polyline class="st0" points="22,4 12,14 9,11 "/>
                           </svg>
                        </span>
                     </div>
                     <div class="testimonial__video-text">
                        <h4>{{ interview.title }}</h4>
                        <!-- <p>{{ interview.text }}</p> -->
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   <Footer footerPadding="true"/>
  </template>
  
  <script>
  import Header from '../components/Home/Header.vue';
  import Breadcrumb from '../components/common/Breadcrumb.vue';
  import Footer from '../components/Home/Footer.vue';
  
  export default {
     name:'BlogPage',
     components:{
        Header,
        Breadcrumb,
        Footer,
     },
     data() {
      return {
         interviews: [
            {
               id: 1,
               url: "https://www.youtube.com/embed/Si1V9z1NDRA",
               title: 'Санчир гарагийн зочин',
               text: 'Ойлгоогүй өнгөрүүлсэн цаг хугацаа л гэж байдаг. Хамгийн гол нь хүүхдүүдийн доторх очыг асааж, хөгжүүлэх нь л чухал'
            },
            {
               id: 2,
               url: "https://www.youtube.com/embed/GSLt0NAtAKc",
               title: "Математикийн мэдлэг бол дэлхийд хүлээн зөвшөөрөгдсөн виз юм",
               text: "Сууж сурна гэдэг нь сандал дээр зүгээр суухыг хэлэхгүй"
            }
            ,
            {
               id: 3,
               url: "https://www.youtube.com/embed/z-rV1NWsXUg",
               title: "Сууж сурна гэдэг нь сандал дээр зүгээр суухыг хэлэхгүй",
               text: "Хүүхдийн сурах сэтгэлзүй"
            },
            {
               id: 4,
               url: "https://www.youtube.com/embed/wsqt8uMGX3E",
               title: "Хоцрогдол гэж юу вэ?",
               text: "Хоцрогдол гэж юу вэ?"
            },
            {
               id: 5,
               url: "https://www.youtube.com/embed/wsqt8uMGX3E",
               title: "Хоцрогдол гэж юу вэ?",
               text: "Хүүхдүүдэд ямар боломж байдаг вэ?"
            },
            {
               id: 6,
               url: "https://www.youtube.com/embed/feueb10n3io",
               title: "Дэлгэцийн хамаарал хамгийн том асуудал болоод байна",
               text: "Зуны сургалтын үр нөлөө"
            }
         ]
      }
     }
  }
  </script>